import { Compiler, Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicLoadComponentBase } from 'anthem-eea-library-ui';
import { environment } from '../../environments/environment';
import { AppConstants } from '../common/constants/app-constants';
import { UserCacheService } from '../common/services/user-cache.service';
import { WcsApiService } from './../common/services/wcs-api.service';

@Component({
  selector: 'app-dynamic-load',
  templateUrl: './dynamic-load.component.html'
})

export class DynamicLoadComponent extends DynamicLoadComponentBase implements OnInit {

  @ViewChild('content', { read: ViewContainerRef }) declare viewContainerRefs: ViewContainerRef;
  public errorMsg: string = "";
  public isLocal: boolean = false;
  constructor(
    protected router: Router,
    protected injector: Injector,
    protected wcsApiService: WcsApiService,
    public meta: Meta,
    public userCacheService: UserCacheService,
    public _compiler: Compiler,
    public pageTitle: Title) {
    super(_compiler,router, injector, meta, pageTitle)
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    this.initializeWcsTemplate();
  }

  /**
   * Method used to initialize WCS Server template
   */
  initializeWcsTemplate() {
    let templatePath: string = this.router.url;
    let lindex = templatePath.lastIndexOf("/");
    templatePath = (lindex > 0) ? templatePath.substring(lindex, 0) : templatePath;
    if (!environment.wcsApi.OWCSContent) {
      this.getWebServerTemplate(templatePath + ".html");
     // this.getWcsTemplate(templatePath + ".html", "");
    } else {
      this.getWcsTemplate(templatePath, "");
    }
  }

  /**
   * Method used to get the query string param based on environment
   */
  getQueryString() {
    let queryString: string = "?portal=secure&environment=staging";
    if (environment.environment.toLocaleUpperCase() === AppConstants.ENV_PROD) {
      queryString = "?portal=secure&environment=prod";
    }
    return queryString;
  }

  /**
   * Method used to get WCS template
   * Added query string in WCS url to differentiate public and secure templates
   */
  getWcsTemplate(templatePath: string, queryString: string) {
    let count = this.userCacheService.getUserData('WCS_API_ERROR_COUNT') ? this.userCacheService.getUserData('WCS_API_ERROR_COUNT') : 0;
    // Fetch content from WCS ONLY if current WCS API Failure Count < wcsApi.errThresholdCount
    if (count < environment.wcsApi.errThresholdCount) {
      this.wcsApiService.getWcsContent(templatePath + queryString).subscribe(data => {
        if (data != undefined && data != null && data != "") {
          this.initiateDynamicLazyModule(data);
        } else {
          console.log("WCS Api returns empty content.");
        }
      }, err => {
      	// Increment WCS API Failure Count, if WCS API throws error.
        count = count + 1;
        //console.log('In getWcsTemplate - Public - Fail Count : ' + count);
        this.userCacheService.setUserData('WCS_API_ERROR_COUNT', count);
        if (templatePath.indexOf(".html") == -1) {
          templatePath = templatePath + ".html";
        }
        this.getWebServerTemplate(templatePath);
      });
    } else {
      // Load content from web server if current WCS API Failure Count > wcsApi.errThresholdCount. This will continue until
      // WCS_API_ERROR_COUNT is reset.  	
      if (templatePath.indexOf(".html") == -1) {
        templatePath = templatePath + ".html";
      }
      this.getWebServerTemplate(templatePath);
    }
  }

  /**
   * Method used to get Web Server template
   */
  getWebServerTemplate(templatePath: string) {
    this.wcsApiService.getWebServerContent(templatePath).subscribe(data => {
      if (data != undefined && data != null && data != "") {
        this.initiateDynamicLazyModule(data);
      } else {
        console.log("Web Server Template returns empty content.");
      }
    }, err => {
      this.errorMsg = err.message;
    }
    );
  }

  /**
   * Method to hide the various alert types
   * @param value
   */
  hideAlertTypes(value) {
    if (value == "negative") {
      this.errorMsg = "";
    }
  }

}